import { useEffect, useState } from 'react';
import { getUserAttributes } from '../../utils/auth';
import { FishCatchDashboardService } from './FishCatchDashboardService';
import { dateToString } from '../../utils/util';
import FishCatchFooter from './FishCatchFooter';
import FishCatchHeader from './FishCatchHeader';
import FishCatchMain from './FishCatchMain';
import { Toolbar } from '@mui/material';
import FishCatchUploadedDataList from './UploadedDataSection/FishCatchUploadedDataList';

const FishCatchDashboard: React.FC = () => {
  const { userName } = getUserAttributes();
  const [dateRange, setDateRange] = useState(
    FishCatchDashboardService.getDefaultDateRange()
  );
  const [refetchData, setRefetchData] = useState(false);
  const [disableExport, setDisableExport] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSpecies, setSelectedSpecies] = useState([{}]);
  const [selectedVessels, setSelectedVessels] = useState([{}]);
  const [selectedFishingTechniques, setSelectedFishingTechniques] = useState([
    {},
  ]);

  //Maintains the state of the menu uploaded data that has been submitted
  const [uploadedDataScreen, setUploadedDataScreen] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onDateRangeChange = (newDateRange: any) => {
    if (newDateRange[0] !== null && newDateRange[1] !== null) {
      const formatNewDateRange = [
        dateToString(newDateRange[0], 'YYYYMMDDHHmmss'),
        dateToString(newDateRange[1], 'YYYYMMDDHHmmss'),
      ];
      setDateRange(formatNewDateRange);
    } else {
      setDateRange(newDateRange);
    }
  };

  return (
    <>
      <FishCatchHeader
        userName={userName}
        onDateRangeChange={onDateRangeChange}
        setRefetchData={setRefetchData}
        disableExport={disableExport}
        dateRange={dateRange}
        setIsLoading={setIsLoading}
        selectedSpecies={selectedSpecies}
        selectedVessels={selectedVessels}
        selectedFishingTechniques={selectedFishingTechniques}
        setUploadedData={setUploadedDataScreen}
        uploadedData={uploadedDataScreen}
      />

      <div style={{ marginTop: '1em' }} />
      <Toolbar />
      {uploadedDataScreen && (
        <FishCatchMain
          userName={userName}
          dateRange={dateRange}
          refetchData={refetchData}
          setRefetchData={setRefetchData}
          setDisableExport={setDisableExport}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setSelectedSpecies={setSelectedSpecies}
          setSelectedVessels={setSelectedVessels}
          setSelectedFishingTechniques={setSelectedFishingTechniques}
        />
      )}

      {uploadedDataScreen === false && (
        <FishCatchUploadedDataList setUploadedData={setUploadedDataScreen} />
      )}

      <FishCatchFooter />
    </>
  );
};

export default FishCatchDashboard;
