import { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Toolbar } from '@mui/material';
import { getUserAttributes } from '../../utils/auth';
import { dateToString } from '../../utils/util';
import IUUHeader from './IUUHeader';
import IUUFooter from './IUUFooter';
import IUUMain from './IUUMain';

const IUUDashboard: React.FC = () => {
  const { userName } = getUserAttributes();
  const [dateRange, setDateRange] = useState([
    dateToString(moment().subtract(1, 'days'), 'YYYYMMDDHHmmss'),
    dateToString(moment(), 'YYYYMMDDHHmmss'),
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onDateRangeChange = (newDateRange: any) => {
    const formatNewDateRange = [
      dateToString(newDateRange[0], 'YYYYMMDDHHmmss'),
      dateToString(newDateRange[1], 'YYYYMMDDHHmmss'),
    ];
    setDateRange(formatNewDateRange);
  };

  return (
    <Box>
      <IUUHeader userName={userName} onDateRangeChange={onDateRangeChange} />

      <div style={{ marginTop: '1em' }} />
      <Toolbar />

      <IUUMain userName={userName} dateRange={dateRange} />
      <IUUFooter />
    </Box>
  );
};

export default IUUDashboard;
