/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { HeaderMenuItemProps } from '../../components/Header/HeaderTypes';
import { Header } from '../../components/Header/Header';
import { LogoSection } from '../../components/Header/LogoSection';
import { HeaderMenuSection } from '../../components/Header/HeaderMenuSection';
import { useEffect, useState } from 'react';
import { MapContext } from './MapContext';
import { Box, Card } from '@mui/material';
import { FeaturesContext } from './FeaturesContext';
import DurationSlider from '../../components/Slider/Component/DurationSlider';
import { isAnyProductSelected } from './HelperService';
import { I18nKey } from '../../translations/I18nKey';
import { useContextSelector } from 'use-context-selector';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import DatePickerV2 from '../../components/DateRangePicker/Component/DatePickerV2';

const marks = [
  {
    value: 0,
    label: '00',
  },
  {
    value: 12,
    label: '12',
  },
];

export const MapHeader: React.FC<any> = ({ userName, onSettingsOpen }) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const selectedDate = useContextSelector(
    MapContext,
    (state) => state.selectedDate
  );
  const dataFilesListByUserRegion = useContextSelector(
    MapContext,
    (state) => state.dataFilesListByUserRegion
  );
  const setUserSelectedDate = useContextSelector(
    MapContext,
    (state) => state.setUserSelectedDate
  );
  const getselectedTimeOrDepthProducts = useContextSelector(
    FeaturesContext,
    (state) => state.getselectedTimeOrDepthProducts
  );
  const onDepthAndFrequencyChange = useContextSelector(
    FeaturesContext,
    (state) => state.onDepthAndFrequencyChange
  );
  const tileData = useContextSelector(
    FeaturesContext,
    (state) => state.tileData
  );
  const [selectedTimeProducts, setSelectedTimeProducts] = useState<any>([]);
  const [frequency, setFrequency] = useState(0);

  const onChange = (
    dateSelected: Date | null,
    action: 'back' | 'forward' | null
  ) => {
    if (!dateSelected) return;

    const newDate = new Date(dateSelected);
    if (action === 'back') {
      newDate.setDate(newDate.getDate() - 1);
    } else if (action === 'forward') {
      newDate.setDate(newDate.getDate() + 1);
    }

    setUserSelectedDate(newDate);
  };

  useEffect(() => {
    if (!isAnyProductSelected(selectedTimeProducts)) {
      setFrequency(0);
    }
  }, [selectedTimeProducts]);

  useEffect(() => {
    setSelectedTimeProducts(getselectedTimeOrDepthProducts('time'));
  }, [tileData]);

  const onDurationChange = (e: any, newValue: any) => {
    sessionStorage.setItem('time', newValue ? newValue : '00');
    setFrequency(newValue);
    onDepthAndFrequencyChange(selectedTimeProducts);
  };

  const menuList: Array<HeaderMenuItemProps> = [
    {
      key: '1',
      icon: SettingsIcon,
      name: intl.formatMessage({
        id: I18nKey.USER_SETTINGS_SETTINGS,
      }),
      itemClass: 'menu__iconFormat',
      onClick: onSettingsOpen,
    },
    {
      key: '2',
      icon: LogoutIcon,
      name: intl.formatMessage({
        id: I18nKey.AUTH_LOGOUT,
      }),
      itemClass: 'menu__iconFormat',
      onClick: () => navigate('/logout'),
    },
  ];

  return (
    <Header
      leftElement={
        <LogoSection
          route={intl.formatMessage({
            id: I18nKey.HEADER_MAPS,
          })}
        ></LogoSection>
      }
      centerElement={
        <>
          <Card
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              height: '54px',
              width: '225px',
              marginTop: '11px',
            }}
          >
            <NavigateBeforeIcon
              fontSize="large"
              onClick={() => onChange(selectedDate || null, 'back')}
              style={{ cursor: 'pointer', paddingLeft: '3px' }}
            />
            <DatePickerV2
              defaultSelection={selectedDate}
              calenderData={dataFilesListByUserRegion}
              format="MM/dd/yyyy"
              onChange={(date: Date | undefined) =>
                onChange(date || selectedDate!, null)
              }
            />
            <NavigateNextIcon
              fontSize="large"
              onClick={() => onChange(selectedDate || null, 'forward')}
              style={{ cursor: 'pointer', paddingRight: '3px' }}
            />
          </Card>

          {isAnyProductSelected(selectedTimeProducts) && (
            <Box
              title={intl.formatMessage({
                id: I18nKey.SLIDER_DURATION,
              })}
              sx={{ marginTop: '11px', marginLeft: '37px' }}
            >
              <DurationSlider
                onChange={onDurationChange}
                marks={marks}
                value={frequency}
              />
            </Box>
          )}
        </>
      }
      rightElement={
        <HeaderMenuSection
          user={userName}
          menuList={menuList}
        ></HeaderMenuSection>
      }
    />
  );
};
